/*eslint-disable */
// import AdminActivityLogModel from '../../Model/AdminActivityLog'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"
let AdminActivityLogs = {
  /**
   * adminActivityLogList
   */
  async adminActivityLogList (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "adminActivityLog_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at adminActivityLogList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * adminActivityLogView
   */
  async adminActivityLogView (context, adminActivityLogId) {
    try {
      let post_data = new FormData();
      post_data.append('acl_id', adminActivityLogId);
      return await request.curl(context, "adminActivityLog_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at adminActivityLogView() and Exception:",err.message)
    }
  }
  
}

export {
  AdminActivityLogs
}
