<template>
  <b-container fluid>
    <b-row>
      <b-col lg='12'>
        <iq-card>
          <template v-slot:headerTitle v-if='!propOpenedInModal'>
            <h4 class='card-title'>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <p v-show='!propOpenedInModal'>
              {{cvCardSubHeader}}
            </p>
            <form action='#' v-if='vmAdminActivityLogFormData && Object.keys(vmAdminActivityLogFormData).length > 0'>
              <div class='form-row'>
                <div class='col-md-12 mb-3'>
                  <label
                    for='validationactivity_log'>
                    {{cvUserNameLabel}}</label>
                  <input
                    v-model='vmAdminActivityLogFormData.user_name'
                    disabled
                    type='text'
                    class='form-control'
                    required/>
                </div>
                <div class='col-md-12 mb-3'>
                  <label
                    for='validationactivity_log'>
                    {{cvUserEmailLabel}}</label>
                  <input
                    v-model='vmAdminActivityLogFormData.user_email'
                    disabled
                    type='text'
                    class='form-control'
                    required/>
                </div>
                <div class='col-md-12 mb-3'>
                  <label
                    for='validationactivity_log'>
                    {{cvModuleObjIdLabel}}</label>
                  <input
                    v-model='vmAdminActivityLogFormData.module_obj_id'
                    disabled
                    type='text'
                    class='form-control'
                    required/>
                </div>
                <div class='col-md-12 mb-3'>
                  <label
                    for='validationactivity_log'>
                    {{cvActivityLogLabel}}</label>
                  <div>
                    {{vmAdminActivityLogFormData.activity_log}}
                  </div>
                </div>
              </div>
              <!-- <div class='form-group'>
                <button v-if='cvLoadingStatus' type='button' class='btn btn-primary' :disabled='cvLoadingStatus'>
                   <b-spinner label='Spinning'></b-spinner>
                </button>
                <button type='button' class='btn btn-primary' @click='adminActivityLogView()'>{{cvSubmitBtn}}</button>
              </div> -->
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model='showToast' name='Toast' :variant='toastVariant' :title='toastTitle'>
      <div v-html='toastMsg'>
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { AdminActivityLogs } from "../../../FackApi/api/adminActivityLog"

export default {
  name: "AdminActivityLogView",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propAdminActivityLogObj: {
      type: Object,
      default: function () {
        return {
          "activity_log": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "View Admin Activity Log",
      cvCardSubHeader: "View Admin Activity Log",
      cvSubmitBtn: "View",
      cvActivityLogLabel: "Activity log",
      cvUserNameLabel: "User Name",
      cvUserEmailLabel: "User Email",
      cvModuleObjIdLabel: "Module Id",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "AdminActivityLog Response",
      vmAdminActivityLogFormData: {}
    }
  },
  mounted () {
    this.adminActivityLogView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmAdminActivityLogFormData) {
          if (!this.vmAdminActivityLogFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * adminActivityLogView
     */
    async adminActivityLogView () {
      try {
        if (this.propOpenedInModal) {
          this.vmAdminActivityLogFormData = this.propAdminActivityLogObj
        }
        else {
          let adminActivityLogId = this.$route.params.acl_id
          let adminActivityLogViewResp = await AdminActivityLogs.adminActivityLogView(this, adminActivityLogId)
          if (adminActivityLogViewResp && adminActivityLogViewResp.resp_status) {
            this.vmAdminActivityLogFormData = adminActivityLogViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at adminActivityLogView() and Exception:", err.message)
      }
    }
  }
}
</script>
